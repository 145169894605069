.all-games-listing .section-heading{font-size:32px;line-height: 40px;margin:0 0 5px;}
.all-games-listing .featured-card{margin:0 0 24px;width:100% !important;border-radius:10px;}
.all-games-listing .image-outer-container{width:80px;height:80px;border-radius:10px;}
.all-games-listing .image-outer-container img{border-radius:10px;}
.all-games-listing .card-right-content .ft-common--text{margin:0 0 10px;}
.all-games-listing .card-heading h4{font-size:24px;height:60px;word-break:break-all;-webkit-line-clamp:2;display:-webkit-box;-webkit-box-orient:vertical;overflow: hidden;text-overflow: ellipsis;}
.all-games-listing .small-common-btn.btn-playgame {min-width:140px !important;}
.all-games-listing.feature-pg .small-common-btn.btn-playgame{min-width:110px !important;height:40px;font-size:14px;}
.all-games-listing .icons img{width:30px;}
.page-search-form{width:100%;max-width:280px;}
.page-search-form input{width:100%;height:42px;border:1px solid #fff;border-radius:7px;background:transparent;color:#fff;padding:5px 35px 5px 10px;z-index:1}
.page-search-form input:focus{box-shadow:none;outline:none;}
.page-search-form .icon-search{position: absolute;top:10px;right:10px;color:#fff;}
.page-search-form input::-webkit-input-placeholder {color:#fff;}
.page-search-form input:-moz-placeholder {color:#fff;opacity:1;}
.page-search-form input::-moz-placeholder {color:#fff;opacity:1;}
.page-search-form input:-ms-input-placeholder {color:#fff;}
.page-search-form input::-ms-input-placeholder {color:#fff;}
.page-search-form input::placeholder {color:#fff;}