/* wellome section */
p.para-text {
    padding-top: 26px;
    letter-spacing: 0px;
    color: rgba(225, 225, 225, 0.9);
    padding-bottom: 60px !important;
}
.deskillz-detail p.para-text {
    width: 870px;
}
ul.moral-services {
    list-style-type: none;
}
ul.moral-services li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    font: normal normal bold 16px/30px Gadugi;
}
ul.moral-services li img {
    margin-bottom: 10px;
}

.content-wraper {
    margin-top: 270px;
}
section.welcome-area {
    position: relative;
    background: transparent url(../../../assets/images/red-rug.png) 0% 0% no-repeat padding-box;
    background-size: 50%;
    height: 100%;
    background-position: top right;
    margin-top: -282px;
    padding-top: 220px;
    padding-bottom: 194px;
}
.payment-in-busds h2 {
    font: normal normal normal 50px/73px Impact;
    text-transform: uppercase;
    margin-bottom: 28px !important;
    text-align: center !important;
}
.payment-in-busds h4 {
    text-align: center;
    font: normal normal normal 26px/41px Impact;
    width: 500px;
    margin-bottom: 40px !important;
}
.payment-in-busds {
    margin-left: 93px;
}
.payment-in-busds .btn {
    margin-top: 40px;
}
/* media quries */
@media only screen and (max-width: 1600px) {
    .content-wraper {
        margin-top: 147px;
    }
    .payment-in-busds h2 {
        font: normal normal normal 42px/43px Impact;
        margin-bottom: 38px !important;
    }
    .payment-in-busds h4 {
        font: normal normal normal 20px/33px Impact;
        width: 363px;
        margin-bottom: 20px !important;
    }
    p.para-text {
        padding-top: 26px;
        font: normal normal normal 15px/26px Gadugi;
        padding-bottom: 60px !important;
    }
    h3.common-heading {
        font-size: 37px;
        line-height: 50px;
    }
    .deskillz-detail p.para-text {
        width: 650px;
    }
}
@media only screen and (max-width: 1399px) {
    section.welcome-area {
        background-size: 50%;
        margin-top: -195px;
        padding-top: 220px;
        padding-bottom: 194px;
    }
    .content-wraper {
        margin-top: 100px;
    }
    .payment-in-busds h2 {
        font: normal normal normal 37px/27px Impact;
        margin-bottom: 38px !important;
    }
}
@media only screen and (max-width: 1199px) {
    .deskillz-detail p.para-text {
        width: 100%;
        padding-bottom: 38px !important;
    }
    section.welcome-area {
        background-size: 70%;
        margin-top: -208px;
        padding-bottom: 100px;
    }
    .payment-in-busds {
        margin-left: 0;
    }
    .payment-in-busds h4 {
        font: normal normal normal 18px/33px Impact;
        width: 100%;
        margin-bottom: 0 !important;
        text-align: center;
    }
}
@media only screen and (max-width: 991px) {
    .payment-in-busds {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .payment-in-busds h2 {
        text-align: left !important;
        margin-bottom: 7px !important;
    }
    .content-wraper {
        margin-top: 39px;
    }
    section.welcome-area {
        background-size: 70%;
        margin-top: -115px;
        padding-bottom: 75px;
    }
    .payment-in-busds h2 {
        font: normal normal normal 33px/43px Impact;
    }
    .deskillz-detail p.para-text {
        font-size: 15px;
        text-align: center;
    }
    .deskillz-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    section.welcome-area {
        margin-top: -152px;
    }
    ul.moral-services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    ul.moral-services li {
        font: normal normal bold 14px/24px Gadugi;
        margin-top: 15px;
    }
    .payment-in-busds {
        margin-top: 40px;
    }
    section.welcome-area {
        padding-bottom: 40px;
    }
}
@media only screen and (max-width: 575px) {
    section.welcome-area {
        background: none;
    }
    .payment-in-busds h2 {
        font: normal normal normal 25px/43px Impact;
    }
    .payment-in-busds h4 {
        font: normal normal normal 18px/22px Impact;
    }
    ul.moral-services li {
        margin-right: 18px;
        font: normal normal bold 14px/24px Gadugi;
    }
}
