.feature-pg {
    position: relative;
    background: transparent url("../../assets/images/featured.png") 0% 0% no-repeat padding-box;
    padding: 140px 0 100px;
    background-size: 100%;
    height: 100%;
}

.heading-wraper {
    margin-bottom: 80px;
}

.explore-more {
    font: normal normal normal 20px/27px Gadugi;
    color: #d11d15;
    text-decoration: none;
}

.featured-card {
    background: #0000001a 0% 0% no-repeat padding-box;
    border: 2px solid #ffffff99;
    border-radius: 20px;
    padding: 15px;
    width: 95% !important;
    /* margin: 0 -48px; */
}

/* .card-left-content,
.card-right-content {
    width: 50%;
} */
.card-right-content {
    padding-left: 20px;
}

.card-left-content .image-outer-container {
    /* width:350px; */
    height: 190px;
}

.card-left-content .image-outer-container img {
    border-radius: 16px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.card-heading h4 {
    font: normal normal bold 30px/36px Gadugi;
}

.card-right-content .ft-common--text {
    margin: 13px 0;
}

.ft-common--text p {
    font-size: 16px;
    line-height: 24px;
    color: rgba(225, 225, 225, 0.9);
}

.common-dot-sliderbtn .slick-dots li {
    margin: 0;
}

.common-dot-sliderbtn .slick-dots {
    text-align: end;
    top: -66px;
    bottom: unset;
    right: 0;
    display: inline-block !important;
    width: auto;
}

.common-dot-sliderbtn .slick-dots li.slick-active button:before {
    color: #fff;
}

.common-dot-sliderbtn .slick-dots li button:before {
    font-family: "slick";
    font-size: 10px;
    line-height: 13px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: "•";
    text-align: center;
    opacity: 0.5;
    border-radius: 100%;
    color: #fff !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    align-items: center;
}

.common-dot-sliderbtn .slick-dots li button:hover:before {
    opacity: 1;
    color: #fff;
    font-family: "slick";
    content: "•";
}

.common-dot-sliderbtn .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
    font-size: 14px;
}

/* most-popular-section css */
.most-popular-section {
    padding: 100px 0;
}

.most-popular-card {
    width: 95% !important;
    text-decoration: none;
}

.profile-info h5 {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.profile-info.d-flex.flex-column.justify-content-center {
    flex: 1;
}

.bottom-profile-content {
    width: 100%;
    margin-left: 10px;
    margin-top: -72px;
}

.profile-info p {
    font-size: 16px;
    line-height: 21px;
    color: rgba(225, 225, 225, 0.9);
}

.profile-info {
    margin-left: 15px;
    width: 100%;
    z-index: 2;
}

.most-popular-image-container {
    position: relative;
}

.most-popular-image-container::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    z-index: 1;
}

.image-box {
    z-index: 2;
}

/* .new-games-img-warper {
    width: 200px;
} */
/* new games section css*/
.new-games-card {
    padding: 15px;
    border-radius: 20px;
    border: 2px dashed #000;
    background: linear-gradient(#000, #000) padding-box, linear-gradient(to bottom, #d040b4 0%, #fe0021 100%) border-box;

    max-width: 97%;
}

.new-games-section {
    padding-bottom: 100px;
}

.new-games-bottom-content {
    padding: 20px 0px 15px;
}

.new-games-image-container {
    height: 382px;
}

.new-games-section .new-games-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* .image-box img { } */
/* "explore-games css */
.bottom-profile-content .image-box {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: 1px solid #fff;
    justify-content: center;
    align-items: center;
}

.bottom-profile-content .image-box.d-flex img {
    height: 70px;
    width: 70px;
    border-radius: 50%;
}

.explore-games-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px;
    width: 95% !important;
}

.explore-games-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 254px; */
    height: 200px;
}

.card-top-sec {
    min-height: 100px;
}

.explore-games-image-container img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

h5.black-text.text-center a {
    text-decoration: none;
    color: #000;
}

.explore-games-bottom-content.profile-info {
    margin-left: 0;
    padding: 23px 0 8px;
}

.most-popular-slider.palyer-site .slick-track {
    width: 100% !important;
}

.most-popular-slider.palyer-site .slick-slide {
    /* width: 25% !important; */
    justify-content: space-between;
    width: 50% !important;
    margin-bottom: 25px;
}

img.img-fluid.slider-img-playersite {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-img-holder {
    width: 754px;
    height: 381px;
}

.card-img-holder img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: fill;
    overflow: hidden;
}

.feature-games-slider {
    padding: 0 0 50px;
}

.feature-games-slider .sliderLeftArrow,
.feature-games-slider .sliderRightArrow {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -43px;
    background: transparent;
}

.feature-games-slider .sliderLeftArrow {
    left: 43%
}

.feature-games-slider .sliderRightArrow {
    right: 43%;
}

.feature-games-slider .slick-prev,
.feature-games-slider .slick-next {
    left: 50%;
    top: 50%;
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.card-left-content .image-outer-container {
    width: 190px;
    height: 150px
}

.game-featured-slider .card-heading h4,
.all-games-listing .card-heading h4 {
    font-size: 26px;
    line-height: 30px;
}

.expl-mst-pop-img-cont {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
}

.expl-mst-pop-img-cont img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.game-featured-slider .card-right-content {
    width: calc(100% - 190px);
}

.game-featured-slider .card-heading h4 {
    height: 60px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: break-spaces;
}

@media (max-width: 1780px) {
    .card-img-holder {
        width: 100% !important;
        height: 100% !important;
    }
}

@media only screen and (max-width: 1680px) {
    .new-games-image-container {
        height: 340px;
    }
}

@media only screen and (max-width: 1600px) {
    .card-heading h4 {
        font-size: 20px;
        line-height: 27px;
    }
}

@media only screen and (max-width: 1440px) {
    .game-feature-s-btns.d-flex {
        flex-direction: column;
    }

    .android-apple-btns.d-flex {
        align-items: start !important;
        justify-content: start !important;
        margin-top: 10px;
    }

    .card-left-content .image-outer-container {
        height: 154px;
    }

    .new-games-img-warper {
        width: 110px;
    }

    .new-games-img-warper img {
        object-fit: contain;
        /* height: 100px; */
        width: 100%;
    }

    .profile-info h5 {
        font-size: 16px;
    }

    .new-games-bottom-content {
        padding: 8px 0px 0px;
    }

    .new-games-image-container {
        height: 290px;
    }

}

@media only screen and (max-width: 1280px) {
    .new-games-image-container {
        height: 255px;
    }
}

@media only screen and (max-width: 1199px) {
    .most-popular-section {
        padding: 60px 0;
    }

    .new-games-section {
        padding-bottom: 60px;
    }

    .feature-pg {
        padding: 100px 0 60px;
    }

    .new-games-image-container {
        height: 220px;
    }
}

@media only screen and (max-width: 991px) {
    .new-games-image-container {
        height: 170px;
    }
}

@media only screen and (max-width: 767px) {
    .featured-card {
        width: 100% !important;
        margin: 0;
    }

    .new-games-image-container {
        height: 120px;
    }

    .new-games-img-warper.d-flex {
        height: 50px;
        width: 50px
    }

    .profile-info h5 {
        font-size: 13px;
    }

    .new-games-image-container .slick-slide img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .bottom-profile-content .image-box {
        height: 40px;
        width: 40px;
    }

    .bottom-profile-content .image-box.d-flex img {
        height: 40px;
        width: 40px;
    }

    .profile-info {
        margin-left: 10px;
    }

    .bottom-profile-content {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 575px) {
    .most-popular-card {
        width: 100% !important;
    }

    .explore-games-image-container {
        width: 100%;
    }

    .explore-games-image-container img {
        width: 100%;
    }

    .explore-more {
        font-size: 12px;
    }

    .feature-pg {
        padding: 90px 0 50px;
    }

    .most-popular-section {
        padding: 30px 0;
    }

    .new-games-section {
        padding-bottom: 30px;
    }

    .new-games-bottom-content {
        flex-direction: column;
    }

    .profile-info h5 {
        font-size: 13px;
        line-height: 20px;
    }

    .btn-playgame,
    .hover-effect.btn {
        font-size: 15px;
    }

    .profile-info p {
        font-size: 13px;
        line-height: 17px;
    }

    .common-dot-sliderbtn .slick-dots {
        top: -40px;
    }

    .card-heading h4 {
        font-size: 15px;
        line-height: 20px;
    }

    .card-right-content {
        padding-left: 10px;
    }

    .explore-games-card {
        width: 100% !important;
    }

    .bottom-profile-content .image-box {
        height: 30px;
        width: 30px;
    }

    .bottom-profile-content .image-box.d-flex img {
        height: 30px;
        width: 30px;
    }

    .new-games-image-container {
        height: 225px;
    }

    .most-popular-slider.palyer-site .slick-slide {
        width: 100% !important;
    }
}

@media only screen and (max-width: 320px) {
    .new-games-image-container {
        height: 130px;
    }
}