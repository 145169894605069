.deskillz-partnership.top-bottom-same-padding {overflow: hidden;}
.loyality-program {
    background: #fff;
    padding: 100px 0;
}
.program-inner-content h2 {
    color: #000;
    font-weight: bold;
    font-size: 46px;
    line-height: 50px;
    margin-bottom: 30px;
}
.program-inner-content p {
    color: #000;
    padding: 35px 0;
    width: 947px;
    line-height: 26px;
}
.program-inner-content ul.list-info {
    list-style-type: none;
}
.program-inner-content ul.list-info li {
    font: normal normal normal 20px/30px Gadugi;
    color: #000;
    margin-bottom: 30px;
}
.program-inner-content ul.list-info li img {
    margin-right: 20px;
    width: 25px;
}
.program-inner-content .btn-holder {
    margin-top: 50px;
}
@media only screen and (max-width: 1600px) {
    .program-inner-content p {
        width: 100%;
    }
    .program-inner-content h2 {
        font-size: 43px;
        line-height: 41px;
    }
}
@media only screen and (max-width: 1399px) {
    .loyality-program {
        padding: 70px 0;
    }
    .program-inner-content h2 {
        font-size: 28px;
        line-height: 20px;
    }
    .program-inner-content p {
        padding: 22px 0;
        line-height: 21px;
        font-size: 14px;
    }
    .program-inner-content ul.list-info li {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .program-inner-content ul.list-info li img {
        margin-right: 17px;
        width: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .loyality-program {
        padding: 50px 0;
    }
    .program-inner-content h2 {
        font-size: 35px;
        line-height: 35px;
    }
    .program-inner-content ul.list-info li {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;
    }
    .program-inner-content ul.list-info li img {
        margin-right: 15px;
        width: 17px;
    }
    .program-inner-content p {
        padding: 25px 0;
    }
}
@media only screen and (max-width: 575px) {
    .loyality-program {
        padding: 30px 0;
    }
    .program-inner-content h2 {
        font-size: 26px;
    }
    .card-inner-content {
        padding: 16px 0;
    }
}
@media only screen and (max-width: 320px){
    .program-inner-content h2 { font-size: 24px;}
}
