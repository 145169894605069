.about-banner-pg {
    position: relative;
    background: transparent url(../../assets/images/about-banner-bg.png) 0% 0% no-repeat padding-box;
    padding: 228px 0 100px;
    background-size: 100%;
    height: 100%;
    z-index: 1;
    background-position: top;
}

.values-detail-info .border-items-around {
    height: 170px;
    width: 170px;
}
.deskillz-partnership {
    background: #fff;
}
p.paragraph-text {
    width: 700px;
}
.whats-coming-for-you-slider .about-slide-holder{background:#fff;border-radius:14px;padding:10px;}
@media only screen and (max-width: 1480px) {
    .values-detail-info .border-items-around {
        height: 120px;
        width: 120px;
    }
    .about-banner-pg {
        padding: 150px 0 10px;
    }
    p.paragraph-text {
        width: 615px;
    }
    .about-banner-pg {
        padding: 1;
    }
}
@media only screen and (max-width: 1399px) {
    .about-banner-pg {
        padding: 150px 0 85px;
    }
    .values-detail-info.system-integration-detail .test-inner img {
        width: 60%;
    }
    .values-detail-info .border-items-around {
        height: 100px;
        width: 100px;
    }
}
@media only screen and (max-width: 768px) {
    p.paragraph-text {
        width: 100%;
        text-align: center;
    }
    .about-banner-pg {
        padding: 150px 0 15px;
    }
}
