.game-detail-bg {
    position: relative;
    /* background: transparent url("../../assets/images/game-detail-bg.png") 0% 0% no-repeat padding-box; */
    padding: 150px 0 120px;
    background-size: 100%;
    height: 100%;
    /* background-size: cover; */
    background-position: top;
}
.game-detail-bg:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: rgba(0,0,0,0.4);
}
.game-detail-bg .battle-upper-section{position: relative;z-index: 1;}
.back-btn {
    font-size: 18px;
    text-decoration: none;
    color: #fff;
}
.battle-slider {
    padding-bottom: 100px;
    max-width: 1770px;
    margin-left: auto;
}
.battelfield-img-container {
    padding: 72px 0;
}
/* .battlefield-detail {
    padding-bottom: 80px;
} */
p.battel-text {
    width: 810px;
    padding: 20px 0 35px;
}
.available-gadgets {
    width: 180px;
    border-radius: 14px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    flex-direction: column;
    margin-left: 12px;
}
.available-gadgets p {
    font-size: 16px;
    color: #000;
    width: auto;
    padding-top: 20px;
}
.availablefor {
    transform: rotate(90deg);
    margin-right: 22px;
    font-size: 24px;
    padding: 0;
}
.battle-slider .image-container {
    width: 97% !important;
    margin: 0 -52px;
}
.game-detail-slider2 .bottom-content p {
    color: #000;
    padding: 5px 0 10px;
}
.game-detail-slider2 .bottom-content {
    padding-top: 20px;
}
.game-detail-slider2 .explore-games-image-container img {
    border-radius: 16px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.battelfield-img-container img{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
}
.available-gadgets .hover-qr-code{-o-transform: scale(0) translate(-50%, -50%);-ms-transform: scale(0) translate(-50%, -50%);-moz-transform: scale(0) translate(-50%, -50%);-webkit-transform: scale(0) translate(-50%, -50%);transform: scale(0) translate(-50%, -50%);position: absolute;left: 50%;top:50%;transform-origin: left top; transition: all ease-in-out 0.3s;}
.available-gadgets.active .hover-qr-code{-o-transform: scale(1) translate(-50%, -50%);-ms-transform: scale(1) translate(-50%, -50%);-moz-transform: scale(1) translate(-50%, -50%);-webkit-transform: scale(1) translate(-50%, -50%);transform: scale(1) translate(-50%, -50%);}
@media only screen and (max-width: 1600px) {
    .availablefor {
        transform: none;
        margin-right: 10px;
        font-size: 18px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .battle-slider {
        padding-bottom: 100px;
        max-width: 100%;
        margin-left: auto;
    }
}
@media only screen and (max-width: 1440px) {
    p.battel-text {
        width: 100%;
        padding: 20px 0 35px;
    }
    /* .game-detail-bg {
        background-size: 100% 54%;
    } */
    .battelfield-img-container {
        padding: 47px 0;
    }
    .availablefor {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1199px) {
    p.battel-text {
        width: 100%;
        padding: 5px 0 13px;
        font-size: 15px;
        line-height: 20px;
    }
    .game-detail-bg {
        padding: 100px 0 50px;
        background-size: 100% 60%;
    }
    .battelfield-img-container {
        padding: 16px 0;
    }
    .battle-slider {
        padding-bottom: 60px;
    }
    .game-detail-slider2 .explore-games-image-container img {
        height: 201px;
    }
}
@media only screen and (max-width: 768px) {
    p.battel-text {
        width: 100%;
        padding: 5px 0 13px;
        font-size: 15px;
        line-height: 20px;
    }
    .game-detail-bg {
        background-size: 100% 50%;
    }
    .battelfield-img-container {
        padding: 16px 0;
        height: 129px;
    }
    .battelfield-img-container img {
        height: 100%;
    }
    .availablefor {
        font-size: 13px;
    }
    .battlefield-detail {
        padding-bottom: 50px;
    }
    .battle-slider {
        padding-bottom: 30px;
    }
}
@media only screen and (max-width: 575px) {
    .game-detail-bg {
        background-size: cover;
    }
}
