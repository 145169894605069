.players-banner {
    position: relative;
    background: transparent url("../../assets/images/Players-bg.png") 0% 0% no-repeat padding-box;
    padding: 238px 0 120px;
    background-size: 100%;
    height: 100%;
    background-position: top;
}

.players-portal-part .program-inner-content h2,
.players-portal-part .program-inner-content p,
.players-portal-part .program-inner-content ul.list-info li {
    color: #fff;
}
.players-portal-part .program-inner-content p {
    width: 815px;
}
.players-portal-part {
    padding-top: 250px;
}
.players-banner::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 37%;
    height: 500px;
    background: transparent url(../../assets/images/slider-bg-ready.png) 0% 0% no-repeat padding-box;
    background-position: right bottom;
    background-size: 100%;
}
.players-loyality-pg.loyality-program {
    background: #f5f5f5;
}
.players-portal-part .video-holder{padding-right:20px;}
.players-portal-part .program-inner-content{padding-left:40px;}
@media only screen and (max-width: 1600px) {
    .players-portal-part .program-inner-content p {
        width: 700px;
        font-size: 15px;
    }
    .players-portal-part .program-inner-content ul.list-info li {
        font-size: 17px;
        margin-bottom: 10px;
    }
    .players-portal-part {
        padding-top: 125px;
    }
}
@media only screen and (max-width: 1399px) {
    .players-portal-part {
        padding-top: 100px;
    }
    .players-portal-part .program-inner-content p {
        width: 550px;
        font-size: 14px;
    }
    .players-portal-part .program-inner-content ul.list-info li {
        font-size: 16px;
    }
}
@media only screen and (max-width: 1199px) {
    .players-banner {
        padding: 200px 0 70px;
    }
    .players-portal-part .program-inner-content p {
        width: 100%;
        font-size: 13px;
    }
    .reward-box {
        justify-content: center;
        align-items: center;
    }
    .players-portal-part {
        padding-top: 60px;
    }
}
@media only screen and (max-width: 991px){
.players-portal-part .video-holder,
.players-portal-part .program-inner-content{padding-left:0;}
}
