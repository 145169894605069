.fullpage-loader-holder {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.9);
}

.fullpage-loader {
    width: 112px;
    height: 112px;
    position: absolute;
    left: 50%;
    top: 50%;
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.fullpage-loader .circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #c92626;
    left: 15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0%;
    }
}

.fullpage-loader .circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.fullpage-loader .circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.fullpage-loader .shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow {
    0% {
        transform: scaleX(1.5);
    }
    40% {
        transform: scaleX(1);
        opacity: .7;
    }
    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.fullpage-loader .shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
}

.fullpage-loader .shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.fullpage-loader span {
    position: absolute;
    top: 75px;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 12px;
    color: #11bfe3;
    left: 15%;
}