.start-to-play .start-heading{background-color: #fff;}
.start-to-play a{ text-decoration: none;}



.play-detail h3.common-heading {text-align: center;}
.ready-for-play {position: relative;background: transparent url(../../../assets/images/slider-bg-ready.png) 0% 0% no-repeat padding-box;
    background-position: right bottom;background-size: 54% 59%; padding: 50px 0px;}
.play-detail {position: relative;background: transparent url(../../../assets/images/readyplay.png) 0% 0% no-repeat padding-box;padding: 222px 0;
    background-position: center;background-size: contain;display: flex;flex-direction: column;justify-content: center;margin-top: -95px;}
.slider-main-container.ready-play-slick-slider {margin-top: -50px;}
/* slider css */
.slider-main-container .slick-prev:before,
.slider-main-container .slick-next:before {display: none;}
.slider-main-container .slick-arrow {width: 12px;height: 12px;top: 15px;right: 18px;border: solid #fff;transition: all ease-in-out 0.3s;}
.slider-main-container .slick-arrow.slick-next {border-width: 2px 2px 0 0;-o-transform: rotate(45deg);-ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);-webkit-transform: rotate(45deg);transform: rotate(45deg);}
.slider-main-container .slick-arrow.slick-prev {right: 14px;left: auto;border-width: 0 0 2px 2px;-o-transform: rotate(45deg);-ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);-webkit-transform: rotate(45deg);transform: rotate(45deg);}
.slider-main-container .slick-arrow:hover {border-color: red;}
.sliderLeftArrow,
.sliderRightArrow {position: absolute;width: 45px;height: 45px;background: #232227;border-radius: 50%;display: flex;justify-content: center;
    align-items: center;border: 1px solid transparent;z-index: 1;}
.slider-main-container.ready-play-slick-slider .sliderLeftArrow {left: -2%;bottom: 53%; border: 1px solid #ffff;}
.slider-main-container.ready-play-slick-slider .sliderRightArrow {right: -2%;bottom: 53%;border: 1px solid #ffff;}
.slider-main-container .slick-list{padding:50px 20px !important;}
.slider-main-container .slick-track {display: flex;align-items: center;}
/* .slider-main-container .slick-active.slick-center .video-container {position: relative; padding: 15px 10px 15px 16px; */
    .slider-main-container .slick-active.slick-center .video-container {position: relative; padding: 15px 15px;
    background: transparent url(../../../assets/images/activeimage.png) 0% 0% no-repeat padding-box;height: auto;background-size: 100% 100%;width:90%;margin:0 auto;}
.img-contain {width: 100%;height: 100%;}
.slider-main-container .slick-active.slick-center .video-container video {display: block; border-radius: 30px;
    clip-path: polygon(25% 3.5%, 75% 3.5%, 75% 0, 100% 0, 100% 100%, 0 100%, 0 0, 25% 0);}
.slider-main-container .slick-active.slick-center .slide-content {visibility: visible;opacity:1;height:auto;}
.slider-main-container .slick-active.slick-center {transform: scale(1.1);margin:0 50px;}
.slider-main-container .slick-active.slick-center img{width:100%;display: none;}
.slider-main-container .video-container video {display: none;}
.slider-main-container .slick-slide img {display: block;margin: 0 auto;width: 80%;height:100%;object-fit: cover;border-radius: 30px;border: 2px solid slategrey;
    clip-path: polygon(25% 3.5%, 75% 3.5%, 75% 0, 100% 0, 100% 100%, 0 100%, 0 0, 25% 0); padding: 6px;}
.slide-content {margin-top: 15px;display: flex;flex-direction: column;justify-content: center;align-items: center;visibility: hidden;opacity:0;height:0;}
.slide-content h4 {font: normal normal bold 18px/24px Gadugi;}
.slide-content p {font: normal normal normal 16px/21px Gadugi;}
.playmode-smart-contract-section {padding: 30px 0 100px;margin-top: -90px;}
.play-mode h3 {font: normal normal bold 26px/35px Gadugi;color: #d31d05;margin-left: 27px;}
ul.play--mode--list {list-style-type: none;}
ul.play--mode--list li {/* font: normal normal normal 16px/24px Gadugi; */color: rgba(225, 225, 225, 0.9);margin-bottom: 20px;margin-top: 20px;width: 403px;}
.play--mode--list img {margin-right: 12px;}
.slider-main-container.ready-play-slick-slider .slider-image{height:380px;}
.slider-main-container.ready-play-slick-slider .slick-slide.slick-active.slick-center.slick-current .slider-image,
.slider-main-container .slick-active.slick-center .slide-content{visibility: hidden;opacity:0;height:0;}
.start-to-play .slick-slide.slick-active.slick-center.slick-current{ margin: 0 auto;padding:0 30px;width:auto !important;}
.slider-main-container.ready-play-slick-slider .slick-center .slider-image{display:none;}
/* @media only screen and (max-width: 1700px) {
    .slider-main-container .slick-slide img {height: 380px;margin: 105px auto 0;}
}
@media only screen and (max-width: 1600px) {
    .slider-main-container .slick-slide img {height: 367px;margin: 111px auto 0;}
}
@media only screen and (max-width: 1500px) {
    .slider-main-container .slick-slide img {height: 325px;margin: 111px auto 0;}
} */

@media only screen and (max-width: 1500px){
    .sliderLeftArrow{
        left: 0;
    }
    .sliderRightArrow {
        right: 0;
    }
}


@media only screen and (max-width: 1399px) {
    .slider-main-container .slick-active.slick-current + .slick-slide + .slick-slide .video-container {margin-top: 65px;}
    /* .slider-main-container .slick-slide img {height: 315px;} */
    ul.play--mode--list li {width: 100%;}
    ul.play--mode--list li {font: normal normal normal 12px/16px Gadugi;margin-bottom: 12px;margin-top: 10px;}
    .play-detail {padding: 194px 0;background-position: center;background-size: 100% 100%;margin-top: -67px;}
    .play-mode h3 {font: normal normal bold 22px/35px Gadugi;}
    /* .ready-play-slick-slider .sliderLeftArrow {left: 3px;} */
    /* .sliderRightArrow {right: -5px;} */
    .slider-main-container .slick-active.slick-center {margin:0 25px;}
    /* .slider-main-container .ready-play-slick-slider */
}
/* @media only screen and (max-width: 1280px) {
    .slider-main-container .slick-slide img {height: 285px;}
} */
@media only screen and (max-width: 1199px) {
    /* .slider-main-container .slick-slide img {height: 260px;} */
    .play-detail {margin-top: -12px;}
    .play-detail {margin-top: -9px;padding: 150px 0;}
    /* .slick-slide.slick-active{width: 290px !important;} */
    .ready-play-slick-slider .sliderLeftArrow { left: -8px !important;}
    .ready-play-slick-slider .sliderRightArrow { right: -15px !important;}
    .slider-main-container .slick-active.slick-center .video-container { width: 90%;}
    .slider-main-container .slick-slide img { width: 60%;}
    .slick-slide.slick-active.slick-center.slick-current { margin:none; padding: 0; width:none !important;}
    .sliderLeftArrow, .sliderRightArrow { width: 40px; height: 40px; }
    .slider-main-container .slick-arrow {  width: 10px;  height: 10px;  top: 14px;  left: 13px;}
}
    /* .slider-main-container .slick-slide img {height: 236px;} */
@media only screen and (max-width: 991px) {
    .ready-for-play {background-size: 100% 53%;}
    .play-detail {margin-top: -5px;padding: 115px 0;}
    .play-detail {background-size: cover;margin-top: 2px;}
    .play-mode h3 {font: normal normal bold 20px/54px Gadugi;margin-left: 27px;}
    /* .slick-slide{
        width: 300 !important;
    } */
    /* .slick-slide.slick-active.slick-center.slick-current{width: 214px !important;} */
    /* .slider-main-container .slick-active.slick-current + .slick-slide + .slick-slide .video-container {background: none;}
    .slider-main-container .slick-active.slick-current + .slick-slide .video-container {padding: 0;}
    .slider-main-container .slick-active.slick-current + .slick-slide .video-container video {display: block;clip-path: polygon(25% 3.5%, 75% 3.5%, 75% 0, 100% 0, 100% 100%, 0 100%, 0 0, 25% 0);border-radius: 30px;}
    .slider-main-container .slick-active.slick-current + .slick-slide .slide-content {visibility: visible;}
    .slider-main-container .slick-active.slick-current + .slick-slide img {display: none;}
    .slider-main-container .slick-active.slick-current + .slick-slide + .slick-slide img {display: block;}
    .slider-main-container .slick-active.slick-current + .slick-slide + .slick-slide .video-container video {display: none;padding: 0;}
    .slider-main-container .slick-active.slick-current + .slick-slide + .slick-slide .slide-content {visibility: hidden;}
    .slider-main-container .slick-active.slick-current + .slick-slide + .slick-slide .video-container {margin-top: 0;padding: 0;}
    .slider-main-container .slick-active.slick-current + .slick-slide .video-container {margin-top: 0;}
    .slider-main-container .slick-active.slick-current + .slick-slide .video-container {position: relative;background: transparent url(../../../assets/images/activeimage.png) 0% 0% no-repeat padding-box;height: auto;background-size: 100% 100%;padding: 15px 10px 15px 16px;margin-top: 50px;} */
    /* .slider-main-container .slick-slide img {height: 363px;margin: 124px auto 0;} */
    .ready-for-play .sliderLeftArrow {left: 0%;}
    .ready-for-play .sliderRightArrow {right: 0%;}
    .ready-for-play .slick-list { margin: 0 24px;}
    /* .slider-main-container .slick-list{padding: 50px 18px !important;} */
}
@media only screen and (max-width: 768px) {
    .sliderRightArrow {right: -0%;}
    .sliderLeftArrow {left: 0%;}
    .playmode-smart-contract-section {padding: 30px 0 60px;margin-top: -30px;}
    .slider-main-container .slick-list{padding: 30px !important;}
    /* .slick-slide{width: 220px !important;} */
    /* .slick-slide.slick-active.slick-center.slick-current{width: 220px !important;} */
    /* .slider-main-container .slick-slide img {height: 282px;margin: 102px auto 0;} */
}
@media only screen and (max-width: 600px) {
    .slider-main-container .slick-active.slick-current + .slick-slide .video-container {background: none;padding: 0;}
    .slider-main-container .slick-slide.slick-active.slick-center.slick-current .video-container {position: relative;background: transparent url(../../../assets/images/activeimage.png) 0% 0% no-repeat padding-box;height: auto;background-size: 100% 100%;padding: 15px 10px 15px 16px;margin-top: 50px;}
    .slider-main-container .slick-active.slick-current + .slick-slide .video-container video {display: none;}
    .slider-main-container .slick-slide.slick-active.slick-center.slick-current .video-container video {clip-path: polygon(25% 3.5%, 75% 3.5%, 75% 0, 100% 0, 100% 100%, 0 100%, 0 0, 25% 0);border-radius: 30px;display: block;}
    .slider-main-container .slick-slide.slick-active.slick-center.slick-current img {display: none;}
    .slider-main-container .slick-slide.slick-active.slick-center.slick-current .slide-content {visibility: visible;}
    .slider-main-container .slick-active.slick-current + .slick-slide .slide-content {visibility: hidden;}
    .slider-main-container .slick-active.slick-current + .slick-slide .video-container video {display: none;}
    /* .slider-main-container .slick-slide img {margin: 59px -18px 9px;} */
    /* .slider-main-container .slick-slide img {height: 257px;} */
    .ready-for-play .slick-list {margin: 0 25px;}
    /* .sliderRightArrow, .sliderLeftArrow { display: none;} */
}
@media only screen and (max-width: 575px){
    .ready-play-slick-slider .sliderLeftArrow { left: 15px !important;}
    .ready-play-slick-slider .sliderRightArrow { right: 5px !important; }
}
@media only screen and (max-width: 479px){
    .sliderLeftArrow, .sliderRightArrow {width: 30px; height: 30px; }
    .slider-main-container .slick-arrow {  width: 8px;  height: 8px;  top: 10px;  left: 10px !important; }
    .slider-main-container .slick-slide.slick-active.slick-center.slick-current .video-container { margin-left: 55px; }
    .ready-play-slick-slider .sliderLeftArrow { left: 4px !important;}
    /* .slider-main-container .slick-slide.slick-active.slick-center.slick-current .slide-content { margin-left: -140px;} */
}
@media only screen and (max-width: 319px) {
    .ready-for-play .slick-list {margin: 0 0px;}
    .sliderRightArrow, .sliderLeftArrow { display: none;}
    /* .sliderLeftArrow { display: none;} */
    .slick-dots li button:before{ height: 10px; width: 10px; border: 1px solid #d31d05; background-color: #d31d05; border-radius: 50%;
        font-family: 'slick'; position: absolute; top: -20px; content: ''; text-align: center; color: #d31d05; }
    .slick-dots li {  position: relative;  display: inline-block;  width: 20px;  height: 20px;  margin: 0 5px;  padding: 0; cursor: pointer; }
    .slick-dots li.slick-active button:before {  width: 25px; height: 10px; border-radius: 30px;}
    .slick-dots li.slick-active button:before { opacity: .75; color: #d31d05 !important;}
}






