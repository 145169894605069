/* dashboard gaaming css */
.dashboard-image-container {
    padding: 100px 0;
}
@media only screen and (max-width: 1600px) {
    .dashboard-image-container {
        padding: 65px 0;
    }
    
}
@media only screen and (max-width: 1199px) {
    .dashboard-image-container {
        padding: 20px 0;
    }
}

/*  */
