.develper-banner {
    position: relative;
    background: transparent url("../../assets/images/developers-bg.png") 0% 0% no-repeat padding-box;
    padding: 238px 0 120px;
    background-size: 100%;
    height: 100%;
    background-position: top;
}
.game-feature-bg {
    position: relative;
    background: transparent url("../../assets/images/game-fearure-bg.png") 0% 0% no-repeat padding-box;
    background-size:cover ;
    min-height:600px;
    background-position: 100% 100%;
}

.develper-banner-content .text-wraper {
    width: 945px;
    margin: 16px auto;
}
.services-area {
    padding-top: 284px;
}
.services-area-content p {
    padding-top: 15px;
    color: rgba(225, 225, 225, 0.9);
}
.service-box {
    border: 1px dashed;
    color: rgba(225, 225, 225, 0.9);
    border-radius: 28px;
    padding: 5px;
}
.services-area .services-detail {
    margin-top: 70px;
}
.service-image-container {
    padding: 45px 15px;
    display: flex;
    justify-content: center;
}
.service-image-container img {
    height: 100px;
}
.box-bottom-footer {
    background: transparent linear-gradient(180deg, #d31d05 0%, #b51500 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 26px 26px;
    opacity: 1;
    padding: 15px;
}
.box-bottom-footer h4 {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
}
.description {
    width: 270px;
    padding: 15px 10px;
}
.develper-banner::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 37%;
    height: 500px;
    background: transparent url(../../assets/images/slider-bg-ready.png) 0% 0% no-repeat padding-box;
    background-position: right bottom;
    background-size: 100%;
}
.developer-pg-wraper .how-it-works {
    background: #fff;
}
.developer-pg-wraper .how-it-works p {
    width: 800px;
}
.game-feature-bg .program-inner-content p {
    color: #fff;
    width: 100%;
}
.game-feature-bg .program-inner-content h2 {
    color: #fff;
}
.game-feature-bg .program-inner-content ul.list-info li {
    color: #fff;
}
.lets-help-succeed.program-inner-content {
    padding-left: 100px;
}
@media only screen and (max-width: 1600px) {
    .services-area {
        padding-top: 180px;
    }
    .developer-pg-wraper .how-it-works p {
        width: 700px;
    }
}
@media only screen and (max-width: 1399px) {
    .develper-banner-content .text-wraper {
        width: 790px;
        margin: 16px auto;
    }
    .services-area {
        padding-top: 140px;
    }
    .developer-pg-wraper .how-it-works p {
        width: 600px;
    }
}
@media only screen and (max-width: 1199px) {
    .services-area {
        padding-top: 100px;
    }
    .developer-pg-wraper .how-it-works p {
        width: 100%;
    }
    .lets-help-succeed.program-inner-content {
        padding-left: 0;
    }
    .service-box {
        width: 100%;
    }
    .description {
        width: 100%;
    }
    .description p {
        font-size: 13px;
    }
     .develper-banner {
        padding: 150px 0 80px;
    }
}
@media only screen and (max-width: 768px) {
    .services-area-content p {
        text-align: center;
    }
    .services-area-content h3 {
        text-align: center;
    }
    .services-area {
        padding-top: 45px;
    }
    .develper-banner {
        padding: 130px 0 60px;
    }
    .game-feature-bg {min-height:350px;background-position:70% 100%;}
}
@media only screen and (max-width: 575px) {
    .game-feature-bg {
        background-size: 100%;
        background-position: bottom;
    }
    
}
@media (max-width: 467px) {
    .versions-list .item{
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .versions-list .item .platform {
        margin: auto;
        margin-left: 0 !important;
        margin-right: 0;
    }
    .versions-list .item .platform .icons .icon {
        margin-right: auto;
    }
    .versions-list .item .platform .icons .icon:not(:last-child) {
        margin-right: 30px;
    }
    .versions-list .item .platform .icons {
        text-align: center;
        margin: 6px auto 0px auto;
    }
}