#main-header.header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background-color: transparent;
    z-index: 11;
}

#main-header.header.custom-container {
    max-width: 1650px;
    margin: 0 auto;
}

.header .navbar {
    padding: 20px 0;
}

.header .navbar a {
    color: #fff;
    font-size: 16px;
    transition: all 0.5s;
    position: relative;
    margin: 0 0 0 70px;
    font: normal normal bold 16px/21px Gadugi;
    padding: 0;
    text-decoration: none;
}

.sit-main-logo {
    width: 170px;
}

#main-header.header .navbar .pages-names a.active,
#main-header.header .navbar .pages-names a:hover {
    color: #ee0202 !important;
}

#main-header.header .navbar .pages-names a:after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -4px;
    background: #fff;
    -o-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}

#main-header.header .navbar .pages-names a:hover:after,
#main-header.header .navbar .pages-names a.active:after {
    width: 100%;
}

.herder-btn-holder {
    position: relative;
    display: flex;
    align-items: center;
}

.wallet-box {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 6px;
    position: relative;
}

.button-lottie>div {
    transform: rotate(90deg);
    position: absolute;
    top: -77px;
    left: -58px;
    z-index: 8;
}

.wallet-box>div {
    position: absolute;
    top: -60px;
    left: -60px;
    z-index: 9;
}

.sticky {
    background-color: #141317;
    height: 90px;
    z-index: 11;
    transition: all ease-in-out 5ms;
}

/* media quries */

@media only screen and (max-width: 1680px) {
    #main-header.header.custom-container {
        max-width: 1450px;
    }
}

@media only screen and (max-width: 1440px) {
    #main-header.header.custom-container {
        max-width: 1250px;
    }
}

@media only screen and (max-width: 1399px) {
    .sit-main-logo {
        width: 120px;
    }

    .header .navbar a {
        margin: 0 0 0 55px;
        font: normal normal bold 13px/21px Gadugi;
    }

    .button-lottie>div {
        top: -77px;
        left: -36px;
        width: 120px !important;
    }

    .wallet-box {
        width: 40px;
        height: 40px;
    }

    .wallet-box>div {
        width: 107px !important;
        position: absolute;
        top: -66px;
        left: -33px;
        z-index: 9;
    }
}

@media only screen and (max-width: 1280px) {
    #main-header.header.custom-container {
        max-width: 1100px;
    }
}

@media only screen and (max-width: 1199px) {
    .header .navbar a {
        margin: 0 0 0 50px;
    }

    /* .header .navbar a {
        color: #fff;
        font-size: 16px;
        transition: all 0.5s;
        position: relative;
        margin: 0 0 0 50px;
        font: normal normal bold 16px/21px Gadugi;
        padding: 0;
        text-decoration: none;
    } */
    #main-header.header.custom-container {
        max-width: 950px;
    }

    .sit-main-logo {
        width: 140px;
    }

    .wallet-box {
        width: 45px;
        height: 45px;
    }

    .wallet-box>div {
        top: -62px;
        left: -48px;
        width: 145px !important;
    }

    .button-lottie>div {
        top: -77px;
        left: -46px;
        width: 145px !important;
    }

    .header .navbar .nav-link {
        font-size: 15px;
    }
}

@media only screen and (max-width: 991px) {
    #main-header.header.custom-container {
        max-width: 850px;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255,255,255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
    }

    #main-header .navbar-toggler {
        border: 1px solid;
        color: #fff;
        box-shadow: none !important;
        outline: none;
        position: absolute;
        right: 10px;
        z-index: 10;
    }

    #main-header .navbar-collapse {
        position: absolute;
        top: 80px;
        width: 100%;
        background: #d31d05;
        left: 12px;
        width: calc(100% - 20px);
        margin: 0 auto;
        padding: 10px 10px;
    }

    a.button-lottie a {
        position: absolute;
        margin: 0;
        right: auto;
        left: -72px;
    }

    .button-lottie>div svg {
        top: -77px;
        left: -61px;
        width: 145px !important;
    }

    .herder-btn-holder {
        margin-right: 65px;
    }

    .herder-btn-holder {
        /* width: 55px;  */
        justify-content: space-between;
    }

    .header .navbar a {
        margin: 0 0 0 0;
    }

    .header .navbar-collapse a {
        padding: 10px;
    }

    #main-header .navbar-collapse {
        padding: 0;
    }

    .header .navbar .herder-btn-holder a {
        margin: 0 0 0 0 !important;
    }

    #main-header.header .navbar .pages-names a:after {
        display: none;
    }

    .wallet-box>div {
        top: -62px;
        left: -42px;
        width: 130px !important;
    }

    #main-header.header .navbar .pages-names a.active,
    #main-header.header .navbar .pages-names a:hover {
        background: #fff;
        color: #d31d05 !important;
    }

    .header .navbar .herder-btn-holder a.button-lottie {
        margin-right: 50px !important;
    }
}

@media only screen and (max-width: 768px) {
    #main-header.header.custom-container {
        max-width: 700px;
    }
}

@media only screen and (max-width: 575px) {
    .sit-main-logo {
        width: 100px;
    }

    .sticky {
        height: 56px;
    }

    .wallet-box>div {
        top: -64px;
        left: -36px;
        width: 119px !important;
    }

    .herder-btn-holder {
        margin-right: 55px;
    }

    .wallet-box {
        width: 36px;
        height: 36px;
        overflow: hidden;
    }

    .wallet-box>div {
        top: -68px;
        left: -40px;
        width: 119px !important;
    }

    .navbar-toggler-icon {
        height: 1em;
        width: 1em;
    }

    .button-lottie>div {
        top: -40px;
        left: -47px;
        width: 139px !important;
        height: 93px !important;
    }

    a.button-lottie.nav-link {
        left: -24px;
        margin-left: 110px !important;
    }

    .sit-main-logo {
        width: 80px;
    }

    .header .navbar {
        padding: 10px 0;
    }

    #main-header.header.custom-container {
        max-width: 500px;
    }

    .header .navbar .herder-btn-holder a.button-lottie {
        margin-right: 14px !important;
    }
}

@media only screen and (max-width: 479px) {
    .herder-btn-holder {
        width: 36px;
    }

    .herder-btn-holder {
        margin-right: 110px;
    }
}