.faqs .nav-tabs {border-bottom: 2px solid rgb(255 255 255 / 65%);margin-bottom: 50px;margin-top: 100px;border: none;}

.faqs .nav-tabs .nav-item .nav-link {border: none;
    color: #fff;
    background: none;
    position: relative;
    padding: 10px 30px; 
    border: 1px solid;
    margin-right: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

.faqs .nav-tabs .nav-item .nav-link.active,.faqs .nav-tabs .nav-item .nav-link:hover {border: 1px solid rgba(211, 29, 5, 1);background: #d31d05;}
.faqs .accordion-item {
    background-color: transparent;
    border: 1px solid rgb(211 29 5);
    margin-bottom: 10px;
}
.faqs .accordion-item .accordion-button.collapsed {
    border: none;
}
.faqs .accordion-item .accordion-button {
    background: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #d31d05;
    box-shadow: none;
    outline: none;
}
.faqs .accordion-item .accordion-button::after {
    filter: invert(1);
}
.blog-detail-page h2{margin:0 0 30px;font-size:32px;line-height:36px;}
.blog-detail-page .blog-thumbnail{height:450px;border-radius:20px;overflow:hidden;margin:0 0 30px;}
.blog-detail-page .blog-thumbnail img{width:100%;height:100%;object-fit: cover;}
.blog-detail-page .blog-content p{margin:0 0 15px;}
.blog-detail-page .blog-content ul{list-style-type: none;padding:0;margin:0 0 30px;}
.blog-detail-page .blog-content ul li{margin:0 0 7px;padding:0 0 0 20px;position: relative;}
.blog-detail-page .blog-content ul li:before{content:"";width:7px;height:7px;border-radius:100%;background:#fff;position: absolute;left:0;top:7px;}
.blog-detail-page table{width:100%;border:solid #535353;border-width:1px 0 0 1px;margin:0 0 30px;}
.blog-detail-page table thead{background:#fff;color:#000}
.blog-detail-page table th, .blog-detail-page table td{border:solid #535353;border-width:0 1px 1px 0;padding:7px 10px;}
@media only screen and (max-width: 575px) {
    .faqs .nav-tabs{
        margin-top: 50px;
    }
.faqs .nav-tabs .nav-item .nav-link{
    margin-right: 10px;
    padding: 10px 20px;
}
}