.top-footer {
    padding: 90px 0;
    background: #232227;
}
.bottom-footer {
    background: #141317;
    padding: 15px 0px;
    display: flex;
    justify-content: center;
    border-bottom: 3px solid #d31d05;
}
.bottom-footer p {
    font: normal normal normal 14px/26px Gadugi;
    color: rgba(225, 225, 225, 0.9);
}

.footer-content {
    padding: 40px 0;
}
.footer-linking.contact-list ul {
    margin: 0;
    padding: 0;
}
li.footer-heading {
    font: normal normal bold 30px/40px Gadugi;
    padding-bottom: 17px;
    position: relative;
}
.footer-logo {
    position: relative;
}
li.footer-heading:after {
    content: "";
    width: 87px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 2px;
    background: #d31d05;
}
.footer-logo::after {
    content: "";
    width: 87px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -20px;
    background: #d31d05;
}
.footer-content p {
    /* font: normal normal normal 18px/24px Gadugi; */
    color: rgba(225, 225, 225, 0.9);
}
.footer-linking.contact-list ul li {
    list-style-type: none;
    margin-bottom: 20px;
}
.footer-linking.contact-list ul li a {
    color: rgba(225, 225, 225, 0.9);
    text-decoration: none;
    position: relative;
    padding-bottom: 5px;
    /* font: normal normal normal 18px/24px Gadugi; */
}
.footer-linking.contact-list ul li a:hover {
    color: #d31d05;
    -o-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.social-icons a {
    font-size: 17px;
    color: #fff;
    text-decoration: none;
    margin-left: 5px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -o-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -webkit-transition: all 0.3s ease-in-out;
    background: #273142;
}
.social-icons {
    display: flex;
    align-items: center;
}
.social-icons a:first-child {
    margin-left: 0;
}
.social-icons a.faTwitter-bg:hover {
    background: #009bf7;
}
.social-icons a.instagram-bg:hover {
    background: #d31d05;
}
.social-icons a.discord-bg:hover {
    background: #5869e9;
}
.social-icons a.telegram-bg:hover {
    background: #28a7e7;
}
@media only screen and (max-width: 1399px) {
    .footer-content p {
        font-size: 14px;
    }
    .footer-linking.contact-list ul li a {
        font-size: 14px;
    }
    li.footer-heading {
        font-size: 22px;
    }
    .footer-logo img {
        width: 125px;
    }
    .footer-linking.contact-list ul li {
        margin-bottom: 6px;
    }
    .footer-logo::after {
        width: 63px;
        bottom: -12px;
    }
    li.footer-heading:after {
        width: 62px;
        bottom: 12px;
    }
    .bottom-footer p {
        font: normal normal normal 12px/26px Gadugi;
    }
}
@media only screen and (max-width: 991px) {
    .footer-content {
        padding: 33px 0 23px;
    }
    .social-icons {
        margin-bottom: 21px;
    }
    .bottom-footer p {
        text-align: center;
        padding: 0 20px;
    }
}

@media only screen and (max-width: 768px) {
    .footer-content p {
        font-size: 14px;
    }
    .footer-linking.contact-list ul li a {
        font-size: 14px;
    }
    .footer-linking.contact-list ul li {
        margin-bottom: 10px;
    }
    li.footer-heading {
        font-size: 20px;
    }
    .footer-logo img {
        width: 130px;
    }
    .top-footer {
        padding: 40px 0;
    }
    .footer-logo::after {
        bottom: -14px;
    }
    li.footer-heading:after {
        width: 57px;
        bottom: 15px;
    }
}
@media only screen and (max-width: 575px) {
    .bottom-footer p {
        font: normal normal normal 12px/18px Gadugi;
    }
    .footer-logo img {
        width: 118px;
    }
}
