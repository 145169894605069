/* site banner css */
#site-banner {
    position: relative;
    background: transparent url(../../../assets/images/banner-bg.png) 0% 0% no-repeat padding-box;
    padding: 100px 0 0;
    background-size: 100% 95%;
    height: 100%;
    z-index: 1;
}
.spider-image-container {
    position: relative;
    display: flex;
    margin-left: -33px;
}
.spider-image-container img {
    width: 60%;
}
img.img-fluid.spider-girl {
    z-index: 3;
}
img.img-fluid.spidar-man {
    right: 4%;
    top: 13%;
    position: absolute;
    z-index: 2;
}
.main-heading {
    font: normal normal bold 140px/186px Gadugi;
    text-transform: uppercase;
    text-align: last;
}
.baner-content p {
    font-size: 28px;
    text-align: right;
}
.baner-content {
    margin-top: 132px;
}
#site-banner::after {
    content: "";
    display: block;
    background: url(../../../assets/images/curve.png);
    position: absolute;
    height: 179px;
    bottom: 0;
    width: 100%;
    bottom: 0;
    background-size: 100% 100%;
    z-index: 1;
}
img.walleimage-animation {
    -webkit-animation: action 1s infinite alternate;
    animation: action 1s infinite alternate;
}
@-webkit-keyframes action {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-8px);
    }
}

@keyframes action {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-8px);
    }
}
/* media queirs */
@media only screen and (max-width: 1600px) {
    .main-heading {
        font-size: 100px;
        line-height: 107px;
    }
    .baner-content p {
        font-size: 20px;
    }
}
@media only screen and (max-width: 1399px) {
    .main-heading {
        font-size: 77px;
        line-height: 85px;
    }
    .baner-content p {
        font-size: 16px;
    }
    #site-banner::after {
        bottom: -20px;
    }
}
@media only screen and (max-width: 1199px) {
    .main-heading {
        font-size: 60px;
        line-height: 70px;
        text-align: right;
    }
    .baner-content p {
        font-size: 18px;
    }
    .baner-content {
        margin-top: 20px;
    }
    #site-banner::after {
        bottom: -98px;
    }
}
@media only screen and (max-width: 991px) {
    .baner-content {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .main-heading {
        font-size: 74px;
        line-height: 85px;
    }
    .baner-content p {
        font-size: 21px;
        text-align: center;
    }
}
@media only screen and (max-width: 768px) {
    .main-heading {
        font-size: 60px;
        line-height: 70px;
    }
    .baner-content p {
        font-size: 18px;
    }
    #site-banner::after {
        background-size: 100% 90%;
    }
}
@media only screen and (max-width: 575px) {
    .main-heading {
        font-size: 32px;
        line-height: 40px;
    }
    .baner-content p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 575px) {
    .main-heading {
        font-size: 24px;
        line-height: 30px;
    }
}