@font-face {
  font-family: "Impact";
  src: url("./assets/fonts/Impact.woff2") format("woff2"),url("./assets/fonts/Impact.woff") format("woff"),url("./assets/fonts/Impact.ttf") format("truetype");
  font-weight: norm;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gadugi";
  src: url("./assets/fonts/Gadugi-Bold.woff2") format("woff2"),url("./assets/fonts/Gadugi-Bold.woff") format("woff"),url("./assets/fonts/Gadugi-Bold.ttf") format("truetype");
  font-weight: bo;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gadugi";
  src: url("./assets/fonts/Gadugi.woff2") format("woff2"),url("./assets/fonts/Gadugi.woff") format("woff"),url("./assets/fonts/Gadugi.ttf") format("truetype");
  font-weight: norm;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "Gadugi";
  font-size: 18px;
  overflow-x: hidden;
}
/* header */
ul {padding-left: 0;}
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {margin-bottom: 0;}
h1,
.h1 {font-size: 140px;line-height: 186px;font-weight: bold;}
h2,
.h2 {font-size: 60px;font-weight: bold;}
h3,
.h3 {font-size: 46px;line-height: 62px;font-weight: bold;text-transform: capitalize;}
h4,
.h4 {font-size: 30px;font-weight: bold;}
h5,
.h5 {font-size: 26px;font-weight: normal;}
h1.subpg-comon-heading {font-size: 70px;line-height: 94px;}
p.subpg-comon-text {font-size: 26px;line-height: 35px;}
.main-wrapper {background: #000000;color: #fff; overflow: hidden;}
.news-bg .accordion-body p{ color: #fff;}
.red-text {color: #d31d05 !important;}
.black-text {color: #000;}
.btn {-o-transition: all ease-in-out 0.3s;-ms-transition: all ease-in-out 0.3s;-moz-transition: all ease-in-out 0.3s;-webkit-transition: all 0.3s ease-in-out;}
.btn-check:focus + .btn,
.btn:focus {outline: 0;box-shadow: none;}
.custom-container {max-width: 1650px;margin: 0 auto;}
/* button style */
.btn-holder {margin-top: 30px;}
.btn-playgame span {position: relative;z-index: 9;}
.btn-playgame {min-width: 209px;height: 70px;padding: 5px 25px;outline: transparent;position: relative;border-radius: 7px;font: normal normal bold 20px/30px Gadugi;color: #fff;
  /* clip-path: polygon(12% 0, 100% 0, 100% 70%, 90% 100%, 0 100%, 0 30%); */
  clip-path: polygon(12% 0, 100% 0, 100% 70%, 90% 100%, 0 100%, 0 35%);
  border: #ffff;position: relative;z-index: 10;font-size: 20px;display: inline-flex;align-items: center;justify-content: center;}
.small-common-btn.btn-playgame {min-width: 120px !important;height: 55px;}
.small-common-btn.btn-playgame::before {height: 40px;width: 40px;top: 6px;}
.red-btn.btn-playgame {background: linear-gradient(  135deg,  transparent 9%,  #fe0021 0%,  #fe0021 92%,  transparent 13%);z-index: 10;color: #fff;text-decoration: none;}
.btn-playgame:after {content: "";background: transparent;clip-path: polygon(12% 0, 100% 0, 100% 70%, 90% 100%, 0 100%, 0 30%);position: absolute;width: calc(100% + -4px);height: calc(100% + -4px);top: 2px;left: 2px;border-radius: 7px;}
.btn-playgame.small {height: 50px;font-size: 15px;min-width: 135px;}
.btn-playgame.small::before,
.red-btn.btn-playgame.small::before {position: absolute !important;content: "";width: 30px;height: 30px;}
.red-btn.btn-playgame:hover:after {background: linear-gradient(  135deg,  transparent 9%,  #fe0021 0%,  #fe0021 92%,  transparent 13%);}
.hover-effect.btn {min-width: 209px;cursor: pointer;background: transparent linear-gradient(180deg, #4082d0 0%, #1c5fb1 100%) 0%  0% no-repeat padding-box;border: 1px solid transparent;outline: none;clip-path: polygon(12% 0, 100% 0, 100% 70%, 90% 100%, 0 100%, 0 30%);color: #fff;position: relative;font-size: 20px;}
.play--mode-button .btn.hover-effect.btn.btn-playgame {background: #fff;color: #000;}
.play--mode-button .hover-effect.btn:hover:after {background: transparent linear-gradient(180deg, #000 0%, #000 100%) 0% 0%  no-repeat padding-box;}
.play--mode-button .btn.hover-effect.btn.btn-playgame:hover {background: transparent linear-gradient(180deg, #fff 0%, #fff 100%) 0% 0%  no-repeat padding-box;color: #ffff;}
.lets-begin-game .btn.hover-effect.btn.btn-playgame:hover {background: transparent linear-gradient(180deg, #fe0021 0%, #fe0021 100%) 0%  0% no-repeat padding-box;color: #ffff;}
.hover-effect.btn:hover,
.red-btn.btn-playgame:hover {background: #fff;}
.hover-effect.btn:hover:after {background: transparent linear-gradient(180deg, #4082d0 0%, #1c5fb1 100%) 0%  0% no-repeat padding-box;}
.btn-playgame::before,
.red-btn.btn-playgame::before {position: absolute !important;content: "";width: 46px;height: 46px;display: block;position: relative;-moz-border-radius: 50%;-webkit-border-radius: 50%;border-radius: 50%;left: 36%;top: 8px;background-color: rgba(255, 255, 255, 0.16);visibility: hidden;opacity: 0;-moz-transition: all 0.4s ease;-o-transition: all 0.4s ease;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;-moz-transform: scale(0.5, 0.5);-ms-transform: scale(0.5, 0.5);-webkit-transform: scale(0.5, 0.5);transform: scale(0.5, 0.5);z-index: 9;-moz-transition: all 0.2s ease;-o-transition: all 0.2s ease;-webkit-transition: all 0.2s ease;transition: all 0.2s ease;}
.btn-playgame:hover:before,
.red-btn.btn-playgame:hover:before {visibility: visible;opacity: 1;-moz-transform: scale(1, 1);-ms-transform: scale(1, 1);-webkit-transform: scale(1, 1);transform: scale(1, 1);}
.form-check {padding: 0;}
.form-check input {padding: 0;height: initial;width: initial;margin-bottom: 0;display: none;cursor: pointer;}
.form-check label {position: relative;}
.form-check label:before {content: "";-webkit-appearance: none;background-color: transparent;border: 2px solid #fe0021;box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),  inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);padding: 8px;display: inline-block;position: relative;vertical-align: middle;cursor: pointer;margin-right: 10px;}
.form-check input:checked + label:after {content: "";display: block;position: absolute;top: 7px;left: 8px;width: 5px;height: 11px;border: solid #fe0021;border-width: 0 2px 2px 0;transform: rotate(45deg);}
.form-wrapper .form-control,
.support-wrapper .form-select {background-color: #ffffff;box-shadow: 0px 3px 6px #0000001a;border: 1px solid #e0e0e0;border-radius: 7px;height: 55px;padding: 10px 20px;}
textarea.form-control {height: auto !important;}
.form-wrapper .action .btn {margin-left: auto;}
.platform .icons {display: flex;margin-bottom: 40px;margin-top: 10px;}
.platform .icons .icon {border-radius: 50%;padding: 6px;width: 35px;height: 36px;display: flex;align-items: center;justify-content: center;margin-right: 10px;cursor: pointer;}
.platform .icons .icon:hover {background: transparent linear-gradient(93deg, rgba(208, 64, 180, 1) 0%, rgba(254, 0, 33, 1) 100%) 0% 0% no-repeat padding-box;transform: scale(1.3);}
.icon.android {background: rgba(154, 201, 0, 1);}
.icon.apple {background: rgba(155, 163, 178, 1);}
.dashbaord-content-wrapper .nav-tabs {border-color: rgba(9, 1, 1, 0.65);margin-bottom: 25px;border-width: 2px;}
.dashbaord-content-wrapper .nav-tabs .nav-link {color: #fff;border: none;border-bottom: 2px solid transparent;padding: 10px 30px;}
.dashbaord-content-wrapper .nav-tabs .nav-link.active,
.games-tabs .nav-tabs .nav-link:hover {background: none;font-weight: bold;border-color: rgba(211, 29, 5, 1);}
::-webkit-scrollbar {width: 10px;}
/* Track */
::-webkit-scrollbar-track {/* box-shadow: inset 0 0 5px grey;  */border-radius: 10px;}
/* Handle */
::-webkit-scrollbar-thumb {background: #000015;border-radius: 10px;}
.top-bottom-same-padding {padding: 50px 0;}
.space-common {padding-bottom: 100px;}
.modal-custom .modal-content {background: transparent;border: none;}
.modal-custom .modal-header {border: none;padding-left: 0;padding-right: 0;}
.modal-custom .modal-title {font-size: 26px;color: #fff;}
.modal-custom .modal-header .btn-close {filter: invert(1);opacity: 1;}
.modal-custom .modal-body {padding: 0;}
.modal-custom .add-game form {flex-direction: column;align-items: center;}
.modal-custom .add-game .upload {margin-bottom: 20px;}
.modal-custom .add-game form input.form-control {min-width: 450px;}
.modal-custom .add-game form input[type="file"] {min-width: auto;}
.modal-custom .add-game .platform {margin-left: 0;margin-top: 25px;margin-bottom: 25px;display: flex;justify-content: space-between;width: 100%;max-width: 450px;align-items: center;}
.modal-custom .platform .icons {display: flex;margin-bottom: 0;margin-top: 0;}
.title .address {word-break: break-all;}
.export-button {text-align: right;margin-bottom: 10px;}
/*pagination*/
.rc-pagination-item{border: 1px solid #d31d05 !important;}
.rc-pagination-item a{color: #d31d05 !important;border: none !important;}
.rc-pagination-item-active {border: 1px solid #d31d05 !important;}
.rc-pagination-item-active a{color: #fff !important;border: none !important;background: #d31d05 !important;}
.rc-pagination-disabled .rc-pagination-item-link, .rc-pagination-disabled:hover .rc-pagination-item-link, .rc-pagination-disabled:focus .rc-pagination-item-link {color: #d31d05 !important;border-color: #d31d05 !important;}

.play-detail a{ text-decoration: none;}
.program-inner-content a{ text-decoration: none;}
section.start-to-play { background: #fff; padding: 100px 0px;}

.news-bg a{ text-decoration: none; color: red;}

/* ckediter */
h3.ckediter-heading { font-size: 24px; font-weight: 400; margin-bottom: 20px;}
p.ckediter-paragraph { font-size: 16px; font-weight: normal; margin-bottom: 20px;}
h3.ckediter-headings { font-size: 22px; font-weight: normal; margin-bottom: 20px;}
.card-inner-content figure img{max-width:100%;height: auto;border-radius:10px;}
/* .card-inner-content .btn-more-holder{height:35px;} */
.card-inner-content .btn-read-more{min-width:100px;height:50px;text-decoration: none;position: absolute;right:0;bottom:5px;font-size:15px;}
.card-inner-content .btn-read-more.btn-playgame:before{width:35px;height:35px;top:6px;}

/* img fixing */
.new-games-img-warper.d-flex { background: #fff;height: 75px;width: 75px;border: 1px solid #fff;border-radius: 50%;align-items: center; justify-content: center;}
.whats-coming-for-you-slider .truncate{height:85px;-webkit-line-clamp:3;display:-webkit-box;-webkit-box-orient:vertical;overflow: hidden;text-overflow:ellipsis;}
@media (min-width: 992px) {
  .modal-custom .modal-dialog {max-width: 650px;}
}

@media only screen and (max-width: 1680px) {
  body{font-size: 16px;}
  .custom-container {max-width: 1450px;}
  h1.subpg-comon-heading {font-size: 55px;line-height: 65px;}
  p.subpg-comon-text {font-size: 23px;line-height: 29px;}
  h1, .h1 {font-size: 100px;line-height: 120px;font-weight: bold;}
  h2, .h2 {font-size: 50px;font-weight: bold;}
  h3, .h3 {font-size: 40px;line-height: 52px;}
  h4, .h4 {font-size: 26px;font-weight: bold;}
  h5, .h5 {font-size: 24px;font-weight: normal;}
  .small-common-btn.btn-playgame {min-width: 62px !important;height: 50px;}
}

@media only screen and (max-width: 1440px) {
  .custom-container {max-width: 1250px;}
  h1.subpg-comon-heading {font-size: 40px;line-height: 40px;}
  p.subpg-comon-text {font-size: 18px;line-height: 27px;}
  .top-bottom-same-padding {padding: 60px 0;}
  .space-common {padding-bottom: 75px;}
  .btn-playgame {height: 60px;min-width: 180px;}
  .game-detail-bg .small-common-btn.btn-playgame,
  .game-feature-s-btns .small-common-btn,
  .new-games-bottom-content .small-common-btn.btn-playgame {min-width: 40px !important;height: 40px;}
  h1, .h1 {font-size: 80px;line-height: 100px;font-weight: bold;}
  h2, .h2 {font-size: 45px;font-weight: bold;}
  h3, .h3 {font-size: 36px;line-height: 43px;}
  h4, .h4 {font-size: 24px;}
  h5, .h5 {font-size: 22px;font-weight: normal;}
  .actions .btn-playgame {min-width: 80px;font-size: 14px;height: 45px}
  .form-wrapper .form-control, .support-wrapper .form-select {height: 50px;padding: 5px 15px;}
}

@media only screen and (max-width: 1280px) {body{    font-size: 15px;}
  .custom-container {max-width: 1100px;}
  h1, .h1 {font-size: 70px;line-height: 90px;font-weight: bold;}
  h2, .h2 {font-size: 40px;font-weight: bold;}
  h3, .h3 {font-size: 30px;line-height: 42px;}
  h4, .h4 {font-size: 20px;}
  h5, .h5 {font-size: 18px;font-weight: normal;}
  .game-detail-slider2 .bottom-content p {padding: 2px 0 4px;font-size: 14px;}
  .actions .btn-playgame{min-width: 100px;font-size: 14px;}
}

@media only screen and (max-width: 1199px) {h1,
  .h1 {font-size: 50px;line-height: 65px;nt-weight: bold;}
  h2, .h2 {font-size: 36px;font-weight: bold;}
  h3, .h3 {font-size: 26px;line-height: 42px;}
  h4, .h4 {font-size: 18px;}
  h5, .h5 {font-size: 16px;font-weight: normal;}
  .custom-container {max-width: 950px;}
  .play--mode-button .btn.hover-effect.btn.btn-playgame {height: 68px;}
  .program-inner-content .btn-holder {margin-top: 29px;}
  .space-common {padding-bottom: 50px;}
  .top-bottom-same-padding {padding: 50px 0;}
  h1.subpg-comon-heading {font-size: 40px;}
  .actions .btn-playgame{min-width: 80px;font-size: 14px;height: 45px;}
  .dashbaord-content-wrapper .nav-tabs .nav-link{padding: 10px 20px;}
}

@media only screen and (max-width: 991px) {
  .custom-container {max-width: 750px;}
  h1.subpg-comon-heading {font-size: 30px;line-height: 30px;}
  .space-common {padding-bottom: 40px;}
  .top-bottom-same-padding {padding: 40px 0;}
  h1.subpg-comon-heading {font-size: 50px; line-height: 55px;}
}

@media only screen and (max-width: 768px) {
  h3, .h3 {font-size: 26px;line-height: 30px;text-transform: capitalize;}
  h1.subpg-comon-heading {font-size: 25px;line-height: 25px;}
  .custom-container {max-width: 550px;}
  .loyality-program .lets-begin-game .btn-playgame {width: 181px !important;font-size: 15px;height: 68px;}
  .play--mode-button .btn.hover-effect.btn.btn-playgame {height: 68px;font-size: 16px;}
  .form-wrapper .form-control,
  .support-wrapper .form-select {height: 45px;}
}

@media only screen and (max-width: 575px) {
  h3, .h3 {font-size: 20px;line-height: 22px;}
  h1.subpg-comon-heading {font-size: 20px;}
  .slider-main-container .slick-slide img {height: 257px;}
  .custom-container {max-width: 500px;}
  p.subpg-comon-text {font-size: 15px;line-height: 22px;}
  .dashbaord-content-wrapper .nav-tabs{justify-content: center;}
}
