.news-bg {
    position: relative;
    background: transparent url(../../assets/images/news-bg.png) 0% 0% no-repeat padding-box;
    padding: 228px 0 130px;
    background-size: 100%;
    height: 100%;
    z-index: 1;
    background-position: top;
}
.news-cardimage-box {
    width: 100%;
}
.news-cardimage-box img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    height: 255px;
}
.news-description .card-contain {
    width: 100% !important;
    margin: auto;
}
.card-contain .text-restrict {
    height:48px;
    -webkit-line-clamp:2;
    display: -webkit-box;
    -webkit-box-orient:vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
.our-values {
    padding-top: 228px;
}
.news-bg .card-contain{min-height: auto;}
.news-bg .card-inner-content .btn-read-more{bottom:0;}
/* responsiveness */
@media only screen and (max-width: 1600px) {
    .our-values {
        padding-top: 150px;
    }
    .text-restrict {
        min-height: 160px;
    }
    .card-contain .text-restrict{min-height:48px}
}
@media only screen and (max-width: 1399px) {
    .our-values {
        padding-top: 130px;
    }
    .our-values {
        padding-top: 100px;
    }
}
@media only screen and (max-width: 1199px) {
    .develper-banner-content .text-wraper {
        width: 100%;
    }
    .our-values {
        padding-top: 106px;
    }
    .news-bg {
        padding: 200px 0 70px;
    }
}
@media only screen and (max-width: 768px) {
    .our-values {
        padding-top: 30px;
    }
    .news-bg {
        padding: 150px 0 50px;
    }
}
.accordion-body{
    overflow-wrap: break-word;
}