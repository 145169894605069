.faqs .nav-tabs {border-bottom: 2px solid rgb(255 255 255 / 65%);margin-bottom: 50px;margin-top: 100px;border: none;}
.faqs .nav-tabs .nav-item .nav-link {border: none;color: #fff;background: none;position: relative;padding: 10px 30px; border: 1px solid;margin-right: 20px;margin-bottom: 20px;font-weight: 600;}
.faqs .nav-tabs .nav-item .nav-link.active,.faqs .nav-tabs .nav-item .nav-link:hover {border: 1px solid rgba(211, 29, 5, 1);background: #d31d05;}
.faqs .accordion-item {background-color: transparent;border: 1px solid rgb(211 29 5);margin-bottom: 10px;}
.faqs .accordion-collapse{border-top:1px solid rgb(211 29 5);}
.faqs .accordion-item .accordion-button.collapsed {border: none;}
.faqs .accordion-header{padding: 20px 10px;}
.faqs .accordion-item .accordion-button {background: none;color: #fff;font-size: 18px;font-weight: 600;box-shadow: none;outline: none; word-break: break-word;}
/* .faqs .accordion-item .accordion-button::after {filter: invert(1);} */
.accordion-button::after, .accordion-button:not(.collapsed)::after { background-image: url('../../assets/images/arrow-accodion.svg');}
@media only screen and (max-width: 575px) {
    .faqs .nav-tabs{margin-top: 50px;}
    .faqs .nav-tabs .nav-item .nav-link{ margin-right: 10px; padding: 10px 20px;}
}