.whats-coming-for-you {
    background: #f5f5f5;
}

.whats-coming-for-you h2 {
    text-align: center;
}

.whats-coming-for-you .program-inner-content p {
    text-align: center;
    width: 100%;
    padding: 10px 0 36px;
}

.whats-coming-for-you-slider {
    padding-top: 30px;
}

.whats-coming-for-you-slider .slick-slide img {
    display: block;
    width: 100%;
    border-radius: 16px;
}

a.card-contain {
    text-decoration: none;
}

.card-contain {
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    border-radius: 14px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 575px;
}
.our-values .card-contain{background:#fff;}
.whatcoming-img-height {
    width: 100%;
    min-height: 350px;
}

.card-contain img {
    height: 348px;
    object-fit: cover;
}
.card-contain .text-restrict img{width:100%;height: auto;}
.whatscoming-padding {
    padding: 0 10px;
}

.card-inner-content h4 {
    font: normal normal bold 18px/26px Gadugi;
    color: #000000;
    margin-bottom: 20px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 55px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-danger-custom {
    color: #d31d05 !important;
    padding-bottom: 20px;
}

.card-inner-content p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0px;
    color: #000000;
}

.card-inner-content {
    padding: 30px 0 60px;
}

.whats-coming-for-you-slider .sliderLeftArrow,
.whats-coming-for-you-slider .sliderRightArrow {
    position: absolute;
    width: 45px;
    height: 45px;
    background: #232227;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.whats-coming-for-you-slider .sliderRightArrow {
    right: -70px;
}

.whats-coming-for-you-slider .sliderLeftArrow {
    left: -70px;
}

.whats-coming-for-you-slider .slick-prev::before,
.whats-coming-for-you-slider .slick-next::before {
    display: none;
}

.whats-coming-for-you-slider .slick-arrow {
    width: 12px;
    height: 12px;
    top: 15px;
    right: 18px;
    border: solid #fff;
    transition: all ease-in-out 0.3s;
}

.whats-coming-for-you-slider .slick-prev {
    right: 14px;
    left: auto;
    border-width: 0 0 2px 2px;
    transform: rotate(45deg);
}

.whats-coming-for-you-slider .slick-next {
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
}

/* .whats-coming-for-you .slick-list {margin: 0 20px;} */
/*responsive media quries */
@media only screen and (max-width: 1600px) {
    .card-inner-content h4 {
        font-size: 17px;
        line-height: 26px;
    }

    .card-inner-content p {
        line-height: 23px;
        font-size: 15px;
    }

    .whats-coming-for-you {
        padding: 80px 0;
    }
    .whatcoming-img-height{
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 1399px) {
    .whats-coming-for-you {
        padding: 60px 0;
    }
   
    .card-inner-content h4 {
        font-size: 14px;
        line-height: 22px;
        height:48px;
    }

    .card-inner-content p {
        line-height: 18px;
        font-size: 11px;
    }
    .card-contain .text-restrict p{font-size:18px;line-height:23px;}

    .whats-coming-for-you-slider .sliderRightArrow {
        right: -50px;
    }

    .whats-coming-for-you-slider .sliderLeftArrow {
        left: -50px;
    }

    /* .whats-coming-for-you-slider .slick-list {margin: 0 50px;} */
}

@media only screen and (max-width: 1199px) {
    .whats-coming-for-you {
        padding: 40px 0;
    }

    .whats-coming-for-you-slider {
        padding-top: 0;
    }

    .whatscoming-padding {
        padding: 0 30px;
    }

    .whats-coming-for-you-slider .sliderRightArrow {
        right: -25px;
    }

    .whats-coming-for-you-slider .sliderLeftArrow {
        left: -25px;
    }
}

@media only screen and (max-width: 991px) {
    .whatscoming-padding {
        padding: 0 10px;
    }

    .whats-coming-for-you-slider .sliderRightArrow {
        right: -50px;
    }

    .whats-coming-for-you-slider .sliderLeftArrow {
        left: -50px;
    }
}

@media only screen and (max-width: 767px) {
    .whatscoming-padding {
        padding: 0 25px;
    }

    .card-contain {
        min-height: 356px;
    }

    .whats-coming-for-you-slider .sliderRightArrow {
        right: -20px;
    }

    .whats-coming-for-you-slider .sliderLeftArrow {
        left: -20px;
    }

    .whats-coming-for-you-slider .sliderLeftArrow,
    .whats-coming-for-you-slider .sliderRightArrow {
        width: 40px;
        height: 40px;
    }

    .whats-coming-for-you-slider .slick-arrow {
        width: 10px;
        height: 10px;
        top: 15px;
        right: 15px;
    }

    .card-contain {
        justify-content: unset;
        min-height: 306px;
        padding:10px 15px;
    }
}

@media only screen and (max-width: 577px) {
    .whats-coming-for-you {
        padding: 30px 0;
    }

    .card-inner-content h4 {
        font-size: 14px;
        line-height: 20px;
        height:43px;
    }

    .card-inner-content p {
        line-height: 18px;
        font-size: 12px;
    }

    .card-contain {
        margin: 0;
    }

    .content-wraper {
        margin-top: 56px;
    }

    a.card-contain {
        width: 100%;
        margin: 0 auto;
    }

    .whats-coming-for-you-slider .sliderRightArrow {
        right: 0;
    }

    .whats-coming-for-you-slider .sliderLeftArrow {
        left: 0;
    }
    .whatcoming-img-height,
    .card-contain img{height:auto;min-height: 183px;}
}

@media only screen and (max-width: 479px) {

    /* .whats-coming-for-you .card-contain {width: 100% !important; } */
    .whats-coming-for-you-slider .sliderLeftArrow,
    .whats-coming-for-you-slider .sliderRightArrow {
        width: 35px;
        height: 35px;
    }

    .whats-coming-for-you-slider .slick-arrow {
        width: 9px;
        height: 9px;
        top: 13px;
        right: 12px;
    }
}