/*dev game owners section */
.game-dev-owner {
    padding-bottom: 100px;
}
p.paragraph-text-2 {
    color: #fff;
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    margin-top: 22px !important;
}
.system-integration-detail {
    padding: 142px 0 132px;
}
.test-inner {
    -o-transform: rotate(226deg);
    -ms-transform: rotate(226deg);
    -moz-transform: rotate(226deg);
    -webkit-transform: rotate(226deg);
    transform: rotate(226deg);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.test-inner img{
    width: 70px;
}
.border-items-around {
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    border: 2px dashed #000;
    background: linear-gradient(#000, #000) padding-box, linear-gradient(to bottom, #d31d05 0%, #d040b4 100%) border-box;
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    position: relative;
    height: 265px;
    width: 265px;
}
.border-items-around:hover {
    background: linear-gradient(#000, #000) padding-box, linear-gradient(to top, #d31d05 10%, #d31d05 20%, #d040b4 70%) border-box;
}
.description-services {
    padding-top: 75px;
    display: flex;
    justify-content: center;
}
.description-services h6 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
.system-integration-detail .description-services {
    position: relative;
    padding-top: 0;
    width: 100%;
}
.system-integration-detail .description-services h6 {
    position: absolute;
    top: 73px;
}
@media only screen and (max-width: 1700px) {
    .border-items-around {
        height: 180px;
        width: 180px;
    }
    p.paragraph-text-2 {
        font: normal normal normal 20px/30px Gadugi;
        margin-top: 12px !important;
    }
}
@media only screen and (max-width: 1399px) {
    .system-integration-detail .description-services h6 {
        position: absolute;
        top: 40px;
    }
    .game-dev-owner {
        padding-bottom: 50px;
    }
    .system-integration-detail {
        padding: 81px 0 160px;
    }
    .border-items-around {
        height: 160px;
        width: 160px;
    }
    .test-inner > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .test-inner.user-box > div svg {
        height: 100px !important;
        width: 100px !important;
    }
    .test-inner > div svg {
        height: 120px !important;
        width: 120px !important;
    }
    .description-services h6 {
        font-size: 13px;
        line-height: 20px;
    }
    h3.common-heading {
        font-size: 24px;
        line-height: 21px;
    }
    p.paragraph-text-2 {
        font-size: 16px;
        line-height: 28px;
    }
    .deskillz-detail p.para-text {
        width: 590px;
        padding-bottom: 38px !important;
        font-size: 14px;
        line-height: 20px;
    }
    .game-dev-owner {
        padding-bottom: 10px;
    }
}
@media only screen and (max-width: 1199px) {
    .deskillz-detail p.para-text {
        width: 100%;
    }
    .game-dev-owner {
        padding-bottom: 50px;
    }
    .border-items-around {
        height: 150px;
        width: 150px;
    }
    .description-services h6 {
        font-size: 13px;
        line-height: 18px;
    }
    .system-integration-detail .description-services h6 {
        top: 39px;
    }
    .system-integration-detail .description-services {
        padding-top: 120px;
    }

    h3.common-heading {
        font-size: 33px;
        line-height: 48px;
    }
    p.paragraph-text-2 {
        font-size: 18px;
    }
    .system-integration-detail {
        padding: 97px 0 0px;
    }
}
@media only screen and (max-width: 768px) {
    .game-dev-owner {
        padding-bottom: 0px;
    }
    h3.common-heading {
        font-size: 25px;
        line-height: 45px;
    }
    p.paragraph-text-2 {
        font-size: 18px;
        line-height: 28px;
        margin-top: 2px !important;
    }
}
@media only screen and (max-width: 575px) {
    h3.common-heading {
        font-size: 22px;
        line-height: 40px;
    }
    p.paragraph-text-2 {
        margin-top: 2px !important;
        font-size: 16px;
        line-height: 19px;
    }
    .test-inner > div svg {
        height: 103px !important;
        width: 103px !important;
    }
}
