.contact-wrapper {margin-top: 100px;}
.contact-wrapper .form-wrapper .form-control {
    background: transparent;
    color: #fff;
    border-color: #7e7d7d;
}
.contact-info {background: #232227;padding: 20px;border-radius: 10px;}
.contact-info h4{
    margin-bottom: 40px;
}
.contact-info li {list-style: none;margin-bottom: 15px;}
.contact-info li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}
.contact-info li a:hover {
color: #d31d05;
}
.contact-info li .fa,.contact-info .fa{margin-right: 8px;}
.contact-info .social-icons {
    margin-top: 30px;
}
.form-wrapper .action-btn {
    margin-top: 20px;
    text-align: right;
}
.form-wrapper .action-btn .btn{
    margin-left: auto;
}
.contact-other-detail h5 {
    color: #D31D05;
    font-size: 20px;
    margin-bottom: 10px;
}
.contact-other-detail h6{
  margin-bottom:10px;
}
.contact-other-detail a{
text-decoration: none;
  font-size: 15px;
  color: #fff;
}
.contact-other-detail a:hover{
     color: #D31D05;
}
.contact-other-detail {
    margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
    .form-wrapper {
        margin-bottom: 40px;
    }
}